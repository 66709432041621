var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.spaceInvitations.length > 0 && _vm.fetchingSpaceInvitations === false
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-center" },
              [
                _vm._l(_vm.availableInvitationTypes, function(invitationType) {
                  return _c(
                    "div",
                    { key: invitationType },
                    [
                      _c("v-checkbox", {
                        staticClass: "mx-4",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            small: "",
                                            color: _vm.getMailIconColor(
                                              invitationType
                                            )
                                          }
                                        },
                                        [_vm._v("mail")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "primary--text caption text-uppercase"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.invitationTypeCheckboxMapping[
                                                invitationType
                                              ]
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.invitationsSwitchData[invitationType],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.invitationsSwitchData,
                              invitationType,
                              $$v
                            )
                          },
                          expression: "invitationsSwitchData[invitationType]"
                        }
                      })
                    ],
                    1
                  )
                }),
                _c("v-spacer"),
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "", "close-on-content-click": false },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "flex-shrink-1",
                                    attrs: {
                                      outlined: "",
                                      text: "",
                                      color: "primary"
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("search")
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "primary--text ml-1 font-weight-bold caption text-truncate flex-shrink-1"
                                        },
                                        [_vm._v(_vm._s(_vm.selectedEmail))]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2874334663
                    ),
                    model: {
                      value: _vm.emailMenu,
                      callback: function($$v) {
                        _vm.emailMenu = $$v
                      },
                      expression: "emailMenu"
                    }
                  },
                  [
                    _c(
                      "v-list",
                      {
                        staticClass: "overflow-y-auto",
                        staticStyle: { "max-height": "500px" },
                        attrs: { nav: "", dense: "" }
                      },
                      [
                        _c(
                          "v-list-item",
                          [
                            _c("v-text-field", {
                              staticClass: "ma-0 pa-0",
                              attrs: {
                                autofocus: "",
                                "append-icon": "search",
                                label: "Filter",
                                dense: "",
                                "single-line": ""
                              },
                              model: {
                                value: _vm.invitationEmailSearch,
                                callback: function($$v) {
                                  _vm.invitationEmailSearch = $$v
                                },
                                expression: "invitationEmailSearch"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._l(_vm.invitationEmails, function(item) {
                          return _c(
                            "v-list-item",
                            {
                              key: item,
                              on: {
                                click: function($event) {
                                  _vm.selectedEmail = item
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-1",
                                    attrs: { text: "", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$store.dispatch(
                                          "spaceStore/fetchSpaceInvitations",
                                          _vm.$route.params.sid
                                        )
                                      }
                                    }
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("refresh")])],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1485262394
                    )
                  },
                  [_c("span", [_vm._v("refresh")])]
                )
              ],
              2
            ),
            _c("v-divider", { staticClass: "mb-5" }),
            _c("v-data-iterator", {
              attrs: { items: _vm.filteredInvitationsList },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _vm._l(props.items, function(item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              index > 0
                                ? _c("v-divider", {
                                    staticClass: "mb-2",
                                    staticStyle: {
                                      opacity: "0.25",
                                      "border-width": "thin"
                                    },
                                    attrs: { inset: "" }
                                  })
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                { attrs: { "two-line": "" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "space-between"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: _vm.getMailIconColor(
                                                  _vm.getInvitationStatus(item)
                                                ),
                                                "x-large": ""
                                              }
                                            },
                                            [_vm._v("mail")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "5" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.requestee_email
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column"
                                                  },
                                                  [
                                                    item.notification_timestamp !==
                                                    null
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "caption mt-1 mb-0"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold "
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Sent on"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateTimeToHuman"
                                                                  )(
                                                                    item.notification_timestamp
                                                                  )
                                                                ) +
                                                                " (UTC) "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.getInvitationStatus(
                                                      item
                                                    ) ===
                                                      _vm.invitationTypes
                                                        .ACCEPTED_INVITATION &&
                                                    _vm.invitationHasTimestamp(
                                                      item
                                                    ) === true
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mt-1 mb-0 caption"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Accepted on"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateTimeToHuman"
                                                                  )(
                                                                    item
                                                                      .invitation_responses[0]
                                                                      .timestamp
                                                                  )
                                                                ) +
                                                                " (UTC) "
                                                            )
                                                          ]
                                                        )
                                                      : _vm.getInvitationStatus(
                                                          item
                                                        ) ===
                                                          _vm.invitationTypes
                                                            .REJECTED_INVITATION &&
                                                        _vm.invitationHasTimestamp(
                                                          item
                                                        ) === true
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mt-1 mb-0"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Rejected on " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateTimeToHuman"
                                                                  )(
                                                                    item
                                                                      .invitation_responses[0]
                                                                      .timestamp
                                                                  )
                                                                ) +
                                                                " (UTC) "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-subtitle",
                                                _vm._l(item.roles, function(
                                                  role,
                                                  index
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "d-flex flex-column"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { left: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    role.role_type !==
                                                                    _vm
                                                                      .invitationRoleTypes
                                                                      .ORG_ROLE
                                                                      ? _c(
                                                                          "div",
                                                                          _vm._g(
                                                                            {},
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "ComponentWithStyledBorder",
                                                                              [
                                                                                role.role_type ===
                                                                                _vm
                                                                                  .invitationRoleTypes
                                                                                  .INSTANCE_ROLE
                                                                                  ? _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "d-flex align-center"
                                                                                      },
                                                                                      [
                                                                                        role.Name ===
                                                                                        "N/A"
                                                                                          ? _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "mr-1 ml-1"
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "help_outline"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          : role.Name ===
                                                                                            _vm
                                                                                              .roleTypes
                                                                                              .INSTANCE_VIEWER
                                                                                          ? _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "mr-1 ml-1"
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "visibility"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          : role.Name ===
                                                                                            _vm
                                                                                              .roleTypes
                                                                                              .INSTANCE_EDITOR
                                                                                          ? _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "mr-1 ml-1"
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "edit"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        role.Instance
                                                                                          ? _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    role.Instance
                                                                                                  )
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          : _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Target deleted"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : role.role_type ===
                                                                                    _vm
                                                                                      .invitationRoleTypes
                                                                                      .SPACE_ROLE
                                                                                  ? _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "d-flex align-center"
                                                                                      },
                                                                                      [
                                                                                        role.Name ===
                                                                                        "N/A"
                                                                                          ? _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "mr-1 ml-1"
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "help_outline"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          : _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "mr-1 ml-1"
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "security"
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              role.Space
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e()
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          role.Name === "N/A"
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "The target object has been deleted"
                                                                )
                                                              ])
                                                            : role.method ===
                                                              _vm
                                                                .roleUpdateOptions
                                                                .OVERWRITE_CURRENT_ROLE
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "This role will overwrite the current user role on the same object, even if the current role is more powerful that this one. "
                                                                )
                                                              ])
                                                            : role.method ===
                                                              _vm
                                                                .roleUpdateOptions
                                                                .OVERWRITE_CURRENT_ROLE_IF_LOWER
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "This role will overwrite the current role only on the same object if the current role is less powerful than this one."
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }),
                                                0
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-col", { attrs: { cols: "1" } }, [
                                        _vm.getInvitationStatus(item) ===
                                          _vm.invitationTypes
                                            .PENDING_INVITATION ||
                                        _vm.getInvitationStatus(item) ===
                                          _vm.invitationTypes.CREATED_INVITATION
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "InvitationActionsDialog",
                                                      {
                                                        attrs: {
                                                          successDispatchString:
                                                            "spaceStore/fetchSpaceInvitations",
                                                          successDispatchId: _vm.$route.params.sid.toString(),
                                                          invitationInfo: item,
                                                          actionType: "resend"
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    _vm._g(
                                                                      {},
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "CopyToClipboard",
                                                                        {
                                                                          attrs: {
                                                                            textToCopy:
                                                                              item.link,
                                                                            buttonClass:
                                                                              "caption font-weight-bold",
                                                                            buttonColor:
                                                                              "primary",
                                                                            isTextButton: true,
                                                                            buttonName:
                                                                              "copy to clipboard",
                                                                            iconButton: true,
                                                                            isSmall: true
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Copy invitation link"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "InvitationActionsDialog",
                                                      {
                                                        attrs: {
                                                          successDispatchString:
                                                            "spaceStore/fetchSpaceInvitations",
                                                          successDispatchId: _vm.$route.params.sid.toString(),
                                                          invitationInfo: item,
                                                          actionType: "revoke"
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _c(
                          "div",
                          [
                            _c(
                              "v-list-item",
                              {
                                attrs: { "two-line": "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("goToInvite", {
                                      value: true
                                    })
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { align: "center" } },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "1" } },
                                      [
                                        _c(
                                          "v-list-item-avatar",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-large": "" } },
                                              [_vm._v("add")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v("Invite")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "no-results",
                    fn: function() {
                      return [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mt-3",
                            attrs: {
                              value: true,
                              color: "error",
                              icon: "warning",
                              text: ""
                            }
                          },
                          [_vm._v("Your search for found no results.")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3886875265
              )
            })
          ],
          1
        )
      : _vm.spaceInvitations.length === 0 &&
        _vm.fetchingSpaceInvitations === false
      ? _c(
          "div",
          [
            _c(
              "v-banner",
              { attrs: { "single-line": "" } },
              [
                _c(
                  "v-avatar",
                  {
                    attrs: { slot: "icon", color: "white", size: "60" },
                    slot: "icon"
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { "x-large": "", icon: "info", color: "info" } },
                      [_vm._v(" info ")]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c(
                    "span",
                    { staticClass: "font-weight-bold primary--text" },
                    [_vm._v("No invitations found")]
                  ),
                  _c("p", [
                    _vm._v(
                      " Currently this space has no invitation history. Consider inviting new users via the "
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-uppercase font-weight-bold secondary--text"
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { small: "", color: "secondary" } },
                          [_vm._v("add")]
                        ),
                        _vm._v("invite")
                      ],
                      1
                    ),
                    _vm._v(" tab above. ")
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      : _vm.fetchingSpaceInvitations === true
      ? _c(
          "div",
          _vm._l(
            [
              { id: "c1", opacity: 1 },
              { id: "c2", opacity: 0.75 },
              { id: "c3", opacity: 0.5 }
            ],
            function(item) {
              return _c("div", { key: item.id, staticClass: "my-6" }, [
                _c(
                  "div",
                  { style: { opacity: item.opacity } },
                  [
                    _c("v-skeleton-loader", {
                      staticClass: "mx-auto",
                      attrs: { type: "list-item-avatar-two-line" }
                    })
                  ],
                  1
                )
              ])
            }
          ),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }