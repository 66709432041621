<template>
    <div>
        <div v-if="spaceInvitations.length > 0 && fetchingSpaceInvitations === false">
            <div class="d-flex flex-wrap align-center">
                <div v-for="invitationType in availableInvitationTypes" :key="invitationType">
                    <v-checkbox class="mx-4" v-model="invitationsSwitchData[invitationType]">
                        <template v-slot:label>
                            <div>
                                <v-icon class="mr-1" small :color="getMailIconColor(invitationType)">mail</v-icon>
                                <span class="primary--text caption text-uppercase">{{ invitationTypeCheckboxMapping[invitationType] }}</span>
                            </div>
                        </template>
                    </v-checkbox>
                </div>
                <v-spacer />
                <v-menu v-model="emailMenu" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <v-btn outlined v-on="on" text color="primary" class="flex-shrink-1">
                            <div class="d-flex align-center">
                                <v-icon small>search</v-icon>
                                <div class="primary--text ml-1 font-weight-bold caption text-truncate flex-shrink-1">{{ selectedEmail }}</div>
                            </div>
                        </v-btn>
                    </template>
                    <v-list style="max-height:500px" class="overflow-y-auto" nav dense>
                        <v-list-item>
                            <v-text-field
                                v-model="invitationEmailSearch"
                                autofocus
                                class="ma-0 pa-0"
                                append-icon="search"
                                label="Filter"
                                dense
                                single-line
                            ></v-text-field>
                        </v-list-item>
                        <v-list-item v-for="item in invitationEmails" :key="item" @click="selectedEmail = item">
                            <v-list-item-title>
                                {{ item }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="ml-1" v-on="on" @click="$store.dispatch('spaceStore/fetchSpaceInvitations', $route.params.sid)" text icon
                            ><v-icon>refresh</v-icon></v-btn
                        >
                    </template>
                    <span>refresh</span>
                </v-tooltip>
            </div>
            <v-divider class="mb-5"></v-divider>
            <v-data-iterator :items="filteredInvitationsList">
                <template v-slot:default="props">
                    <div v-for="(item, index) in props.items" :key="index">
                        <v-divider inset v-if="index > 0" style="opacity: 0.25; border-width: thin" class="mb-2" />
                        <v-list-item two-line>
                            <v-row align="center" justify="space-between">
                                <v-col cols="1">
                                    <v-icon :color="getMailIconColor(getInvitationStatus(item))" x-large>mail</v-icon>
                                </v-col>
                                <v-col cols="5">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.requestee_email }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <div class="d-flex flex-column">
                                                <span class="caption mt-1 mb-0" v-if="item.notification_timestamp !== null">
                                                    <span class="font-weight-bold ">Sent on</span>
                                                    {{ item.notification_timestamp | dateTimeToHuman }} (UTC)
                                                </span>
                                                <span
                                                    v-if="
                                                        getInvitationStatus(item) === invitationTypes.ACCEPTED_INVITATION &&
                                                            invitationHasTimestamp(item) === true
                                                    "
                                                    class="mt-1 mb-0 caption"
                                                >
                                                    <span class="font-weight-bold">Accepted on</span>
                                                    {{ item.invitation_responses[0].timestamp | dateTimeToHuman }} (UTC)
                                                </span>
                                                <span
                                                    v-else-if="
                                                        getInvitationStatus(item) === invitationTypes.REJECTED_INVITATION &&
                                                            invitationHasTimestamp(item) === true
                                                    "
                                                    class="mt-1 mb-0"
                                                >
                                                    Rejected on {{ item.invitation_responses[0].timestamp | dateTimeToHuman }} (UTC)
                                                </span>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            <div v-for="(role, index) in item.roles" :key="index" class="d-flex flex-column">
                                                <v-tooltip left>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-if="role.role_type !== invitationRoleTypes.ORG_ROLE" v-on="on">
                                                            <ComponentWithStyledBorder>
                                                                <span v-if="role.role_type === invitationRoleTypes.INSTANCE_ROLE" class="d-flex align-center">
                                                                    <v-icon class="mr-1 ml-1" v-if="role.Name === 'N/A'">help_outline</v-icon>
                                                                    <v-icon v-else-if="role.Name === roleTypes.INSTANCE_VIEWER" class="mr-1 ml-1"
                                                                        >visibility</v-icon
                                                                    >
                                                                    <v-icon v-else-if="role.Name === roleTypes.INSTANCE_EDITOR" class="mr-1 ml-1">edit</v-icon>
                                                                    <span v-if="role.Instance">{{ role.Instance }}</span>
                                                                    <span v-else>Target deleted</span>
                                                                </span>
                                                                <span v-else-if="role.role_type === invitationRoleTypes.SPACE_ROLE" class="d-flex align-center">
                                                                    <v-icon v-if="role.Name === 'N/A'" class="mr-1 ml-1">help_outline</v-icon>
                                                                    <v-icon v-else class="mr-1 ml-1">security</v-icon>
                                                                    {{ role.Space }}
                                                                </span>
                                                            </ComponentWithStyledBorder>
                                                        </div>
                                                    </template>
                                                    <span v-if="role.Name === 'N/A'">The target object has been deleted</span>
                                                    <span v-else-if="role.method === roleUpdateOptions.OVERWRITE_CURRENT_ROLE"
                                                        >This role will overwrite the current user role on the same object, even if the current role is more
                                                        powerful that this one.
                                                    </span>
                                                    <span v-else-if="role.method === roleUpdateOptions.OVERWRITE_CURRENT_ROLE_IF_LOWER"
                                                        >This role will overwrite the current role only on the same object if the current role is less powerful
                                                        than this one.</span
                                                    >
                                                </v-tooltip>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="1">
                                    <div
                                        v-if="
                                            getInvitationStatus(item) === invitationTypes.PENDING_INVITATION ||
                                                getInvitationStatus(item) === invitationTypes.CREATED_INVITATION
                                        "
                                        class="d-flex align-center"
                                    >
                                        <div>
                                            <InvitationActionsDialog
                                                successDispatchString="spaceStore/fetchSpaceInvitations"
                                                :successDispatchId="$route.params.sid.toString()"
                                                :invitationInfo="item"
                                                actionType="resend"
                                            >
                                            </InvitationActionsDialog>
                                        </div>
                                        <div>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <CopyToClipboard
                                                            :textToCopy="item.link"
                                                            buttonClass="caption font-weight-bold"
                                                            buttonColor="primary"
                                                            :isTextButton="true"
                                                            buttonName="copy to clipboard"
                                                            :iconButton="true"
                                                            :isSmall="true"
                                                        ></CopyToClipboard>
                                                    </div>
                                                </template>
                                                <span>Copy invitation link</span>
                                            </v-tooltip>
                                        </div>
                                        <div>
                                            <InvitationActionsDialog
                                                successDispatchString="spaceStore/fetchSpaceInvitations"
                                                :successDispatchId="$route.params.sid.toString()"
                                                :invitationInfo="item"
                                                actionType="revoke"
                                            >
                                            </InvitationActionsDialog>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </div>
                    <div>
                        <v-list-item @click="$emit('goToInvite', { value: true })" two-line>
                            <v-row align="center">
                                <v-col cols="1">
                                    <v-list-item-avatar>
                                        <v-icon x-large>add</v-icon>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="3">
                                    <v-list-item-content>
                                        <v-list-item-title>Invite</v-list-item-title>
                                    </v-list-item-content>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </div>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning" text class="mt-3">Your search for found no results.</v-alert>
                </template>
            </v-data-iterator>
        </div>
        <div v-else-if="spaceInvitations.length === 0 && fetchingSpaceInvitations === false">
            <v-banner single-line>
                <v-avatar slot="icon" color="white" size="60">
                    <v-icon x-large icon="info" color="info">
                        info
                    </v-icon>
                </v-avatar>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold primary--text">No invitations found</span>
                    <p>
                        Currently this space has no invitation history. Consider inviting new users via the
                        <span class="text-uppercase font-weight-bold secondary--text"><v-icon small color="secondary">add</v-icon>invite</span> tab above.
                    </p>
                </div>
            </v-banner>
        </div>
        <div v-else-if="fetchingSpaceInvitations === true">
            <div
                v-for="item in [
                    { id: 'c1', opacity: 1 },
                    { id: 'c2', opacity: 0.75 },
                    { id: 'c3', opacity: 0.5 }
                ]"
                :key="item.id"
                class="my-6"
            >
                <div :style="{ opacity: item.opacity }">
                    <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { sortArray } from '@/utils'
const CopyToClipboard = () => import('@/components/CopyToClipboard')
const InvitationActionsDialog = () => import('@/modules/invitations/components/InvitationsActionsDialog')
const ComponentWithStyledBorder = () => import('./SpaceManagementComponentWithStyledBorder')

export default {
    mixins: [enumsData],
    components: {
        CopyToClipboard,
        InvitationActionsDialog,
        ComponentWithStyledBorder
    },
    data() {
        return {
            search: '',
            expand: false,
            pendingInvitations: true,
            revokedInvitations: true,
            rejectedInvitations: true,
            expiredInvitations: true,
            brokenInvitations: true,
            invitationsSwitchData: {
                ACCEPTED: true,
                PENDING: true,
                REJECTED: true,
                REVOKED: true,
                EXPIRED: true,
                BROKEN: true,
                CREATED: true
            },
            invitationEmailSearch: '',
            selectedEmail: 'All invitations',
            emailMenu: false,
            invitationTypes: {
                ACCEPTED_INVITATION: 'ACCEPTED',
                PENDING_INVITATION: 'PENDING',
                REJECTED_INVITATION: 'REJECTED',
                REVOKED_INVITATION: 'REVOKED',
                EXPIRED_INVITATION: 'EXPIRED',
                BROKEN_INVITATION: 'BROKEN',
                CREATED_INVITATION: 'CREATED',
                SENT_INVITATION: 'SENT'
            },
            invitationTypeCheckboxMapping: {
                ACCEPTED: 'ACCEPTED',
                PENDING: 'PENDING',
                REJECTED: 'REJECTED',
                REVOKED: 'REVOKED',
                EXPIRED: 'EXPIRED',
                BROKEN: 'BROKEN',
                CREATED: 'UNSENT',
                SENT: 'SENT'
            },
            roleUpdateOptions: {
                OVERWRITE_CURRENT_ROLE: 0,
                OVERWRITE_CURRENT_ROLE_IF_LOWER: 1
            }
        }
    },
    computed: {
        ...mapState('spaceStore', ['spaceInvitations', 'fetchingSpaceInvitations']),
        invitationEmails() {
            if (this.spaceInvitations) {
                const fullEmailList = this.spaceInvitations.map(invitation => invitation.requestee_email)
                const invitationArray = [].concat.apply(['All invitations'], fullEmailList).filter((v, i, a) => a.indexOf(v) === i)
                const filteredInvitationArray =
                    this.$data.invitationEmailSearch && invitationArray.length
                        ? invitationArray.filter(instance => instance.toLowerCase().includes(this.$data.invitationEmailSearch.toLowerCase()))
                        : invitationArray
                return filteredInvitationArray
            }
            return []
        },
        availableInvitationTypes: function() {
            var invitationTypes = []
            if (this.spaceInvitations && this.spaceInvitations.length) {
                this.spaceInvitations.forEach(invitation => {
                    const invitationType = this.getInvitationStatus(invitation)
                    if (invitationTypes.includes(invitationType) === false) {
                        invitationTypes.push(invitationType)
                    }
                })
                return invitationTypes
            }
            return []
        },
        displayedInvitationTypes: function() {
            var invitationTypes = []
            if (this.$data.invitationsSwitchData.ACCEPTED === true && this.availableInvitationTypes.includes(this.$data.invitationTypes.ACCEPTED_INVITATION)) {
                invitationTypes.push(this.$data.invitationTypes.ACCEPTED_INVITATION)
            }
            if (this.$data.invitationsSwitchData.PENDING === true && this.availableInvitationTypes.includes(this.$data.invitationTypes.PENDING_INVITATION)) {
                invitationTypes.push(this.$data.invitationTypes.PENDING_INVITATION)
            }
            if (this.$data.invitationsSwitchData.REVOKED === true && this.availableInvitationTypes.includes(this.$data.invitationTypes.REVOKED_INVITATION)) {
                invitationTypes.push(this.$data.invitationTypes.REVOKED_INVITATION)
            }
            if (this.$data.invitationsSwitchData.REJECTED === true && this.availableInvitationTypes.includes(this.$data.invitationTypes.REJECTED_INVITATION)) {
                invitationTypes.push(this.$data.invitationTypes.REJECTED_INVITATION)
            }
            if (this.$data.invitationsSwitchData.EXPIRED === true && this.availableInvitationTypes.includes(this.$data.invitationTypes.EXPIRED_INVITATION)) {
                invitationTypes.push(this.$data.invitationTypes.EXPIRED_INVITATION)
            }
            if (this.$data.invitationsSwitchData.CREATED === true && this.availableInvitationTypes.includes(this.$data.invitationTypes.CREATED_INVITATION)) {
                invitationTypes.push(this.$data.invitationTypes.CREATED_INVITATION)
            }
            if (this.$data.invitationsSwitchData.BROKEN === true && this.availableInvitationTypes.includes(this.$data.invitationTypes.BROKEN_INVITATION)) {
                invitationTypes.push(this.$data.invitationTypes.BROKEN_INVITATION)
            }
            return invitationTypes
        },
        filteredInvitationsList: function() {
            const filterByStatusType = this.spaceInvitations.filter(invitation => this.displayedInvitationTypes.includes(this.getInvitationStatus(invitation)))
            if (this.$data.selectedEmail === 'All invitations') {
                const sortedData = sortArray(filterByStatusType, 'invid', 'descending', false)
                return sortedData
            } else {
                const filteredData = filterByStatusType.filter(invitation => invitation.requestee_email === this.$data.selectedEmail)
                const sortedData = sortArray(filteredData, 'invid', 'descending', false)
                return sortedData
            }
        }
    },
    methods: {
        getInvitationStatus: function(invitationData) {
            if (
                invitationData.invitation_responses.length > 0 &&
                invitationData.invitation_responses[0].response === this.$data.invitationTypes.ACCEPTED_INVITATION
            ) {
                return this.$data.invitationTypes.ACCEPTED_INVITATION
            } else if (
                invitationData.invitation_responses.length > 0 &&
                invitationData.invitation_responses[0].response === this.$data.invitationTypes.REJECTED_INVITATION
            ) {
                return this.$data.invitationTypes.REJECTED_INVITATION
            } else if (invitationData.status_name === this.$data.invitationTypes.REVOKED_INVITATION) {
                return this.$data.invitationTypes.REVOKED_INVITATION
            } else if (
                invitationData.status_name === this.$data.invitationTypes.SENT_INVITATION &&
                invitationData.valid === 'False' &&
                invitationData.invitation_responses.length === 0
            ) {
                return this.$data.invitationTypes.EXPIRED_INVITATION
            } else if (
                invitationData.status_name === this.$data.invitationTypes.SENT_INVITATION &&
                invitationData.valid === 'True' &&
                invitationData.invitation_responses.length === 0
            ) {
                return this.$data.invitationTypes.PENDING_INVITATION
            } else if (
                invitationData.status_name === this.$data.invitationTypes.CREATED_INVITATION &&
                invitationData.valid === 'True' &&
                invitationData.invitation_responses.length === 0
            ) {
                return this.$data.invitationTypes.CREATED_INVITATION
            } else {
                return this.$data.invitationTypes.BROKEN_INVITATION
            }
        },
        invitationHasTimestamp: function(invitationData) {
            if (
                invitationData.invitation_responses &&
                invitationData.invitation_responses.length &&
                invitationData.invitation_responses[0].timestamp !== undefined
            ) {
                return true
            } else {
                return false
            }
        },
        getMailIconColor: function(status) {
            if (status === this.$data.invitationTypes.ACCEPTED_INVITATION) {
                return 'success'
            } else if (status === this.$data.invitationTypes.REJECTED_INVITATION) {
                return 'yellow darken-2'
            } else if (status === this.$data.invitationTypes.PENDING_INVITATION) {
                return 'secondary'
            } else if (status === this.$data.invitationTypes.REVOKED_INVITATION) {
                return 'error'
            } else if (status === this.$data.invitationTypes.EXPIRED_INVITATION) {
                return 'primary'
            } else if (status === this.$data.invitationTypes.CREATED_INVITATION) {
                return 'purple'
            } else {
                return 'info'
            }
        }
    },
    mounted() {},
    watch: {
        emailMenu: function(nextVal) {
            if (nextVal && this.$data.invitationEmailSearch.length) {
                this.$data.invitationEmailSearch = ''
            }
        }
    }
}
</script>
